<hr>
<div class="speaker-profile" *ngIf="schedule">
    <div class="images">
        <img [src]="schedule.speaker.imageUrl">
        <div class="companyImgs">
            <img [src]="schedule.speaker.companyImgUrl">
            <img [src]="schedule.speaker.company2ImgUrl">
        </div>
    </div>

    <div class="details">
        <div class="info">
            <div class="timeAndLocation">
                <p>{{schedule.time}}</p>
                <div *ngIf="hasLocation" class="pill">
                    <p>Online Presentation</p>
                </div>
            </div>
            <h2>{{schedule.speaker.name}}</h2>
            <p class="position">{{schedule.speaker.position}}</p>
            <p class="bioText">Bio: </p>
            <p class="bio" [ngClass]="{'truncated': !isFullTextView}">{{schedule.speaker.bio}}</p>
            <button (click)="isFullTextView = !isFullTextView">{{isFullTextView ? 'Read Less' : 'Read More'}}</button>
        </div>
        <div class="topic">
            <hr>
            <h3>Topic: {{schedule.speaker.topic}}</h3>
        </div>
    </div>
</div>