import { Component, Input } from '@angular/core';
import { ISchedule } from '../../../features/meetups-fan-sites/interfaces/fan-sites.interface';

@Component({
  selector: 'fan-sites-schedule',
  templateUrl: './fan-sites-schedule.component.html',
  styleUrls: ['./fan-sites-schedule.component.scss'],
})
export class FanSitesScheduleComponent {
  @Input() schedule!: ISchedule;
  @Input() hasLocation: boolean = false;
  isFullTextView: boolean = false;
}
